import React, { useState } from "react";
import WinningPopup from "./WinningPopup";
import "./GameTable.css";

type GameTableProps = {
  sayings: string[][];
};

export const GameTable: React.FC<GameTableProps> = ({ sayings }) => {
  const [selectedItems, setSelectedItems] = useState<boolean[][]>(
    Array.from({ length: 5 }, () => Array(5).fill(false))
  );
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handleClick = (rowIndex: number, cellIndex: number) => {
    const _selectedItems: boolean[][] = [...selectedItems];
    _selectedItems[rowIndex][cellIndex] = !_selectedItems[rowIndex][cellIndex];
    setSelectedItems(_selectedItems);

    if (isBingo()) {
      setShowPopup(true);
    }
  };

  const isBingo = (): boolean => {
    // Check rows
    if (selectedItems.some((row) => row.every((cell) => cell))) {
      return true;
    }
    // Check columns
    if (
      selectedItems.some((_, columnIndex) =>
        selectedItems.every((row) => row[columnIndex])
      )
    ) {
      return true;
    }
    // Check first diagonal
    if (selectedItems.every((row, index) => row[index] === true)) {
      return true;
    }
    if (
      selectedItems.every(
        (row, index) => row[selectedItems.length - index - 1] === true
      )
    ) {
      return true;
    }
    return false;
  };

  const cellStyle = (
    rowIndex: number,
    cellIndex: number
  ): React.CSSProperties => {
    return selectedItems[rowIndex][cellIndex]
      ? {
          border: "2px solid rgba(255, 80, 80, 1.0)",
        }
      : {
          border: "2px solid rgba(180, 180, 180, 1.0)",
        };
  };

  return (
    <div className="table-holder">
      <table>
        <tbody>
          {sayings.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  onClick={() => handleClick(rowIndex, cellIndex)}
                  style={cellStyle(rowIndex, cellIndex)}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {showPopup && <WinningPopup onClose={() => setShowPopup(false)} />}
    </div>
  );
};
