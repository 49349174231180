import React from "react";
import "./WinningPopup.css";

type WinningPopupProps = {
  onClose: () => void;
};

const audio = new Audio("/bingo-sound.mp3");

const WinningPopup: React.FC<WinningPopupProps> = ({ onClose }) => {
  audio.play();

  return (
    <div>
      <div className="popup-overlay">
        <div className="popup-content">
          <h2 className="popup-heading">BINGOOO!!!</h2>
          <p>Nice one.</p>
          <button className="popup-button" onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default WinningPopup;
