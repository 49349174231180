import { useEffect, useState } from "react";
import { sayings } from "./components/Data";
import { GameTable } from "./components/GameTable";
import "./App.css";
import IntroScreen from "./components/IntroScreen";

const App: React.FC = () => {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowIntro(false);
    }, 4500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="App">
      {showIntro ? (
        <IntroScreen />
      ) : (
        <header className="App-header">
          <h1 className="App-heading">Leimer-Bingo</h1>
          <GameTable sayings={sayings()} />
        </header>
      )}
    </div>
  );
};

export default App;
