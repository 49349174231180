import _ from "lodash";

export const sayings = (): string[][] => {
  const _sayings: string[] = [
    "Fett",
    "Thor",
    "Geniale Schlampe",
    "Damals war alles besser",
    "Wir sind alle Ingenieure",
    "Sie sind ja völlig High",
    "Meine Oma ist 123 Jahre alt",
    "Meine Enkel gehen auf eine Privatschule",
    "Danke, mein Bruder",
    "2. Klassige Studenten an ner 3. Klassigen Uni",
    "Das sieht schlampig aus",
    "Meister Gembris",
    "Grundlagen der E-Technik",
    "Setzen sie sich beim Mittag zu mir",
    "GUTEN MORGEN",
    "Das ist nicht pädagogisch richtig",
    "15min Pause?",
    "Wenn sie grüßen, wird man sie direkt als Student erkennen",
    "Das wird immer falsch gemacht, auch in der Schule meiner Enkel",
    "Stoff der 8. Klasse",
    "Was ist denn heute los?",
    "Richtig und wichtig!",
    "PSPICE",
    "Der Touch ist ja doof",
    "Mit dem Zeigestab auf dem Tablet malen",
    "Ich muss nachfüttern",
    "Tab aus seinem Programm raus",
    "Würde Olaf Schubert sagen",
    "Nur wer schreibt, der bleibt",
    "Saft geben",
    "Oberlausitz",
    "Warum ist das so langsam?",
    "Gibts noch Fragen?",
    "Aufregen über Fischer´s Methodik zu Prüfen",
    "Meister Fischer",
    "Über Tafel beschweren",
    "Rechnen Sie es Zuhause nach",
    "Nur weil Sie mitschreiben, haben Sie es noch nicht verstanden",
    "Sonst ärgere ich mich heute Abend"
  ];

  const rndSayings: string[] = _.shuffle(_sayings).slice();
  const cols: number = 5;
  const sayingsTable: string[][] = Array(5)
    .fill("")
    .map((_, index) => rndSayings.slice(index * cols, (index + 1) * cols));

  return sayingsTable;
};
